.projectBox_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectBox_container {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 3em;
}

.projectBox_image {
    width: 14em;
    height: 14em;
    margin-right: 30px;
}

.github_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    text-decoration: none;
    color: white;
}

.pText {
    border-bottom: white 1px solid;
}

.github_logo {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.bottomHr {
    border: none;
    height: 1px;
    background: silver;
    width: 100%; /* Adjust width as needed */
}

@media screen and (max-width: 768px) {
    .projectBox_container {
        flex-direction: column;
        align-items: center;
    }

    .projectBox_image {
        width: 50vw;
        height: 50vw;
        margin-right: 0;
    }
}