.about_wrapper {
    max-width: 50%;
    margin: 0 auto;
    padding-top: 8vh;
}

.content_wrapper {
    display: flex;
    flex-direction: row; /* Aligns items horizontally */
}

.text_section {
    flex: 1; /* Adjust as needed to set the width of the text section */
    padding-right: 20px; /* Adds some space between text and images */
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates a responsive grid */
    grid-column-gap: 1rem;
    grid-row-gap: 1rem; /* Adjust the space between grid items */
    flex: 0.5; /* Adjust as needed to set the width of the grid */
    align-self: center; /* Aligns grid vertically in the center */
}


@media (max-width: 768px) {
    .about_wrapper {
        max-width: 80%;
    }

    .text_section {
        padding: 0;
    }

    .about_block {
        flex-direction: column;
    }

    .content_wrapper {
        flex-direction: column;
    }

    .text_section, .grid {
        flex: none;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* Creates a responsive grid */
        grid-column-gap: 1rem;
        grid-row-gap: 1rem; /* Adjust the space between grid items */
        flex: 0.5; /* Adjust as needed to set the width of the grid */
        align-self: center; /* Aligns grid vertically in the center */
    }
}