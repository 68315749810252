.navbar_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: backdrop-filter 0.3s ease-in-out;
}

.navbar_wrapper.scrolled {
    backdrop-filter: blur(15px);
}

.navbar__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 5vh; /* Reduced from 7vh to 5vh */
    padding-top: 0.25em; /* Reduced for less vertical space */
    padding-bottom: 0.25em; /* Add padding to bottom for space */
}

.navbar__container ul {
    list-style: none;
    padding: 0;
    display: flex;
}

li {
    display: flex;
    align-items: center;
}

.navbar__container li a {
    transition: border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 0 1.75em 4px; /* Reduced bottom padding to lift the border */
}

.nav_icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 15px;
}

@media screen and (max-width: 768px) {
    .desktop {
        display: none;
    }

    .nav_icon {
        margin-right: 0;
    }

    .navbar__container li a {
        padding: 0 8vw 8px; /* Adjusted for smaller screens */
    }
}