.landing_wrapper {
    display: flex;
    max-width: 50%;
    margin: 0 auto;
    height: 100vh;
    align-items: center;
}

.landing_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.pfp {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-right: 30px;
    box-shadow: black 0 0 2px;
}

@media screen and (max-width: 768px) {
    .landing_wrapper {
        max-width: 80%;
    }

    .landing_container {
        flex-direction: column;
    }

    .landing_text h1 {
        text-align: center;
    }

    .pfp {
        margin-right: 0;
        margin-bottom: 30px;
    }
}