.youtube_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    margin: 0 auto;
    height: 100vh;
}

.youtube_video {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}