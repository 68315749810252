.contact_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    margin: 0 auto;
    height: 100vh; /* Full viewport height */
}

.contact_container {
    align-items: center; /* Center content horizontally */
    width: 100%; /* Ensure it occupies the full width of the parent */
}

.contact_icons {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 1.25em auto;
}

.contact_icons a img {
    width: 3em;
    height: 3em;
    margin: 0 20px;
}

.contact_info p {
    text-align: center;
    margin: 0 auto;
}

.contact_info a {
    text-decoration: none;
    color: white;
    border-bottom: white 1px solid;
}

.contact_info {
    margin-bottom: 1.25em;
}

.text_divider {
    --text-divider-gap: 1rem;
    display: flex;
    align-items: center;
    font-size: 0.9375rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.text_divider::before, .text_divider::after {
    content: '';
    height: 1px;
    background-color: silver;
    flex-grow: 1;
}

.text_divider::before {
    margin-right: var(--text-divider-gap);
}

.text_divider::after {
    margin-left: var(--text-divider-gap);
}

.bottomHr {
    border: none;
    height: 1px;
    background: silver;
    width: 100%; /* Adjust width as needed */
}
